import {
  convertConservationState,
  convertStandard,
  PropertyType,
} from '../../../../api/enumerations';
import { SaveElementData } from '../../../../api/sample/types';

interface PresentTableHeadValues {
  id: number;
  label: string;
  value?: string | number;
  small?: boolean;
}

export const presentTableHead = (
  propertyType?: PropertyType,
  data?: SaveElementData
): PresentTableHeadValues[] => {
  return [
    {
      id: 1,
      label: 'endereço',
      value: data?.description.street || '-',
    },
    {
      id: 2,
      label: 'número',
      value: data?.description.number || '-',
      small: true,
    },
    {
      id: 3,
      label: 'complemento',
      value: data?.description.address_complement || '-',
    },
    {
      id: 4,
      label: 'bairro',
      value: data?.description.district || '-',
    },
    {
      id: 5,
      label: 'município',
      value: data?.description.city || '-',
    },
    {
      id: 6,
      label: 'estado',
      value: data?.description.state || '-',
      small: true,
    },
    {
      id: 7,
      label: 'CEP',
      value: data?.description.zipcode || '-',
    },
    {
      id: 8,
      label: 'latitude',
      value: data?.description.latitude || '-',
    },
    {
      id: 9,
      label: 'longitude',
      value: data?.description.longitude || '-',
    },
    {
      id: 10,
      label: 'informante',
      value: data?.description.advertiser.name || '-',
    },
    {
      id: 11,
      label: 'telefone',
      value: data?.description.advertiser.phone || '-',
    },
    {
      id: 12,
      label: 'site',
      value: data?.description.link || '-',
    },
    {
      id: 13,
      label: 'preço total',
      value: data?.description.sell_price || 0,
    },
    {
      id: 14,
      label:
        propertyType === PropertyType.HOUSE
          ? 'área construída'
          : 'área privativa',
      value: data?.description.area || 0,
    },
    ...(propertyType === PropertyType.HOUSE
      ? [
          {
            id: 15,
            label: 'área de terreno',
            value: data?.description.land_area || 0,
          },
        ]
      : []),
    {
      id: 16,
      label: 'dormitórios',
      value: data?.description.bedrooms || 0,
    },
    {
      id: 17,
      label: 'nº de suítes',
      value: data?.description.suites || 0,
    },
    {
      id: 18,
      label: 'banheiros',
      value: data?.description.suites || 0,
    },
    {
      id: 19,
      label: 'vagas',
      value: data?.description.parking_spaces || 0,
      small: true,
    },
    {
      id: 20,
      label: 'idade estimada (anos)',
      value: data?.age || 0,
    },
    {
      id: 21,
      label: 'estado de conservação',
      value:
        data?.preservation_state && typeof data.preservation_state === 'number'
          ? convertConservationState(data.preservation_state)
          : data?.preservation_state,
    },
    {
      id: 22,
      label: 'padrão construtivo',
      value:
        data?.constructive_standard &&
        typeof data.constructive_standard === 'number'
          ? convertStandard(data.constructive_standard)
          : data?.constructive_standard,
    },
  ];
};
