/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { sampleAPI, samplesListAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import { IconCloseMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/samples';
import { validateXlsxFile } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';
import { CustomTextField } from '../../../CustomInput';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../UI/Button';
import { StyledDialog } from '../../../UI/Dialog';
import { DialogTitle } from '../../../UI/Typography';
import { ButtonBox, DialogContainer, FormStyled } from './styles';

interface UploadExcelProps {
  sampleId?: number;
  workOrderId?: number;
  updateSample: () => Promise<void>;
  closePrevDialog?: () => void;
}
export function UploadExcel({
  sampleId,
  workOrderId,
  updateSample,
  closePrevDialog,
}: UploadExcelProps): JSX.Element {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File>();

  const { open, handleClose, handleOpen, handleDownloadXlsx } = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();

  const reset = (): void => {
    setFileName('');
    setFile(undefined);
    handleClose();
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (!file) {
      handleSnackbar('Upload do arquivo é obrigatório.', true);
      return;
    }

    const formData = new FormData();
    formData.append('xlsx_file', file);
    if (workOrderId) formData.append('work_order_id', workOrderId.toString());

    if (sampleId) {
      try {
        const response = await samplesListAPI.importSampleElements(
          sampleId,
          formData
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente');
        }

        handleSnackbar('Elementos adicionados com sucesso', false);
        updateSample();
        reset();
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    } else {
      if (!workOrderId) {
        handleSnackbar('Algo deu errado, tente novamente', true);
        return;
      }
      try {
        const response = await sampleAPI.importElementsFromXLSX(formData);
        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente');
        }

        handleSnackbar('Elementos adicionados com sucesso', false);
        updateSample();
        reset();
        if (closePrevDialog) {
          closePrevDialog();
        }
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    }
  };

  const handleSampleModel = async (): Promise<void> => {
    try {
      const response = await sampleAPI.getSampleModel();
      if (response.ok) {
        handleDownloadXlsx(response);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>
  ): undefined | FileList => {
    let filesData;
    if (!e.target.files?.item(0) || !validateXlsxFile(e.target.files[0].type)) {
      handleSnackbar('Formato incorreto, selecione um arquivo XLSX', true);
      return filesData;
    }
    const { files } = e.target;
    filesData = files;

    setFileName(files[0].name);
    setFile(files[0]);
    return filesData;
  };

  return (
    <>
      {workOrderId ? (
        <RoundedButton disableTouchRipple onClick={handleOpen}>
          {Constants.uploadSpreadsheet}
        </RoundedButton>
      ) : (
        <OutlinedButton width="md" onClick={handleOpen}>
          {Constants.uploadExcel}
        </OutlinedButton>
      )}
      <StyledDialog
        open={open}
        onClose={reset}
        aria-labelledby="Import sample elements from xlsx file"
      >
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.uploadElements}</DialogTitle>
          <OutlinedButton width="lg" onClick={handleSampleModel}>
            {Constants.downloadModel}
          </OutlinedButton>
          <FormStyled component="form" id="upload-xlsx" onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center" gap={2}>
              <CustomTextField
                id="filename"
                label={Constants.sendFile}
                value={fileName}
              />
              <label htmlFor="uploadExcel">
                <input
                  accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="uploadExcel"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    handleFileUpload(e);
                  }}
                />
                <OutlinedButton width="ms" component="span">
                  {Constants.selectFile}
                </OutlinedButton>
              </label>
            </Box>
            <ButtonBox>
              <OutlinedButton width="ms" onClick={reset}>
                {Constants.cancel}
              </OutlinedButton>
              <FilledButton width="ms" form="upload-xlsx" type="submit">
                {Constants.save}
              </FilledButton>
            </ButtonBox>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
