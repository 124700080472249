/* eslint-disable react/require-default-props */
import { CircularProgress } from '@mui/material';
import { useState } from 'react';

import { samplesListAPI } from '../../../../../api';
import {
  convertPropertyType,
  convertRegistrationUf,
  PropertyType,
  RegistrationUf,
  StatusCode,
} from '../../../../../api/enumerations';
import { CustomTextField } from '../../../../../components/CustomInput';
import { CloseIcon, FilledButton } from '../../../../../components/UI/Button';
import { StyledDialog } from '../../../../../components/UI/Dialog';
import { DialogTitle } from '../../../../../components/UI/Typography';
import { IconCloseMS } from '../../../../../constants/icons';
import { Constants } from '../../../../../constants/sampleCreation';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { ButtonBox, DialogContainer, FormStyled } from './styles';

interface AddSampleProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  submitLoading: boolean;
  setSubmitLoading: (value: boolean) => void;
  workOrderUf: RegistrationUf;
  workOrderCity: string;
  workOrderPropertyType: PropertyType;
  saveElementsOnList: (
    sampleListId: number,
    newSample: boolean
  ) => Promise<void>;
  isDisabled: boolean;
}

export function AddSample({
  open,
  setOpen,
  submitLoading,
  setSubmitLoading,
  workOrderUf,
  workOrderCity,
  workOrderPropertyType,
  saveElementsOnList,
  isDisabled,
}: AddSampleProps): JSX.Element {
  const [regionName, setRegionName] = useState('');

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();

  const reset = (): void => {
    setOpen(false);
    setRegionName('');
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    setSubmitLoading(true);

    const data = {
      region_name: regionName,
      real_estate_kind: workOrderPropertyType,
      city: workOrderCity,
      uf: workOrderUf,
    };

    try {
      const response = await samplesListAPI.addSampleList(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      saveElementsOnList(response.data.id, true);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <FilledButton
        width="ms"
        onClick={() => setOpen(true)}
        disabled={isDisabled}
      >
        {Constants.saveNewSample}
      </FilledButton>
      <StyledDialog
        open={open}
        onClose={reset}
        aria-labelledby="Add new sample"
      >
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.newSample}</DialogTitle>
          <FormStyled component="form" id="add-sample" onSubmit={handleSubmit}>
            <CustomTextField
              required
              id="region-name"
              label={Constants.zone}
              value={regionName}
              setValue={setRegionName}
            />
            <CustomTextField
              id="city"
              label={Constants.city}
              value={workOrderCity}
            />
            <CustomTextField
              id="registration-uf"
              label={Constants.uf}
              value={convertRegistrationUf(workOrderUf)}
            />
            <CustomTextField
              id="property-type"
              label={Constants.propertyType}
              value={convertPropertyType(workOrderPropertyType)}
            />
            <ButtonBox>
              <FilledButton form="add-sample" type="submit">
                {submitLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  Constants.save
                )}
              </FilledButton>
            </ButtonBox>
          </FormStyled>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
