import { Checkbox, CircularProgress, Grid } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { workOrderAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import { WorkOrderData } from '../../../../api/workOrders/types';
import { formatReal, getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';
import ConfirmationDialog from '../../../Dialog/ConfirmationDialog';
import { FilledButton, OutlinedButton } from '../../../UI/Button';
import {
  BoxContainer,
  BoxTitle,
  ButtonsSpace,
  StyledLabel,
  StyledTextField,
} from './styles';

interface ArbitraryValueProps {
  propertyData: WorkOrderData;
  initialValue: number;
  arbitraryCallback: (value: number, reason: string) => Promise<void>;
}

export function ArbitraryValue({
  propertyData,
  initialValue,
  arbitraryCallback,
}: ArbitraryValueProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<number>(initialValue);
  const [percentage, setPercentage] = useState<number>(0);
  const [reason, setReason] = useState<string>('');
  const [valueError, setValueError] = useState<boolean>(false);
  const [percentageError, setPercentageError] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const minValue = formatReal(initialValue * 0.85);
  const maxValue = formatReal(initialValue * 1.15);

  const { osId } = useGeneral();
  const { handleSnackbar } = useSnackbar();

  useEffect(() => {
    if (propertyData.arbitrary_value) setValue(propertyData.arbitrary_value);
    if (propertyData.arbitrary_value_percentage) {
      const convertPercentage =
        (propertyData.arbitrary_value_percentage - 1) * 100;
      setPercentage(convertPercentage);
    }
    if (propertyData.arbitrary_value_reason)
      setReason(propertyData.arbitrary_value_reason);
  }, [propertyData]);

  const convertToFloatNumber = (formattedValue: string): number => {
    const cleanedValue = formattedValue
      .replace(/[R$\s]/g, '')
      .replace(',', '.');
    return parseFloat(cleanedValue);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const convertedValue = convertToFloatNumber(e.target.value);
    const valueSize = convertedValue.toFixed(2);
    const initialSize = initialValue.toFixed(2);
    if (
      !Number.isNaN(convertedValue) &&
      valueSize.length === initialSize.length
    ) {
      const newPercentage =
        ((convertedValue - initialValue) / initialValue) * 100;
      if (newPercentage >= -15 && newPercentage <= 15) {
        setValue(convertedValue);
        setPercentage(parseFloat(newPercentage.toFixed(2)));
        setValueError(false);
        setPercentageError(false);
      } else {
        setValueError(true);
      }
    } else {
      setValueError(true);
    }
  };

  const handlePercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const convertedPercentage = parseFloat(e.target.value.replace('%', ''));
    if (!Number.isNaN(convertedPercentage)) {
      if (convertedPercentage >= -15 && convertedPercentage <= 15) {
        setPercentage(convertedPercentage);
        const newValue =
          initialValue + (initialValue * convertedPercentage) / 100;
        setValue(parseFloat(newValue.toFixed(2)));
        setValueError(false);
        setPercentageError(false);
      } else {
        setPercentageError(true);
      }
    } else {
      setPercentageError(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (valueError || percentageError) {
      handleSnackbar('Adicione valores válidos.', true);
      return;
    }

    setSubmitLoading(true);

    const convertedPercentage = 1 + percentage / 100;

    const formData = new FormData();
    formData.append('arbitrary_value', value.toString());
    formData.append(
      'arbitrary_value_percentage',
      convertedPercentage.toString()
    );
    formData.append('arbitrary_value_reason', reason);

    try {
      const response = await workOrderAPI.editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Dados alterados com sucesso!', false);
      arbitraryCallback(value, reason);
      setSubmitLoading(false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setSubmitLoading(false);
    }
  };

  const handleClearValues = async (): Promise<void> => {
    try {
      const response = await workOrderAPI.resetArbitraryValues(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Dados removidos com sucesso!', false);
      arbitraryCallback(initialValue, '');
      setSubmitLoading(false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
      setSubmitLoading(false);
    }
  };

  return (
    <BoxContainer component="form" id="arbitrary" onSubmit={handleSubmit}>
      <BoxTitle>
        <StyledLabel
          id="form-control"
          label="campo de arbítrio"
          control={
            <Checkbox
              color="primary"
              checked={open}
              onChange={(event) => {
                setOpen(event.target.checked);
              }}
            />
          }
        />
      </BoxTitle>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <NumericFormat
            required
            disabled={!open}
            customInput={StyledTextField}
            id="value-arbitrary"
            label="valor"
            color="secondary"
            prefix="R$ "
            decimalSeparator=","
            decimalScale={2}
            thousandsGroupStyle="thousand"
            thousandSeparator=" "
            allowNegative={false}
            inputProps={{ maxLength: 16 }}
            value={value}
            error={valueError}
            helperText={
              valueError
                ? 'valor fora da variação permitida'
                : `variação permitida de ${minValue} à ${maxValue}`
            }
            onChange={(e) => handleValueChange(e)}
          />
        </Grid>
        <Grid item xs={3}>
          <NumericFormat
            required
            disabled={!open}
            customInput={StyledTextField}
            id="percentage-arbitrary"
            label="percentual"
            color="secondary"
            suffix="%"
            decimalSeparator="."
            decimalScale={2}
            allowNegative
            inputProps={{ maxLength: 7 }}
            value={percentage}
            error={percentageError}
            helperText={
              percentageError
                ? 'valor fora da variação permitida'
                : 'variação permitida de -15% à 15%'
            }
            onChange={(e) => handlePercentageChange(e)}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <StyledTextField
            required
            disabled={!open}
            id="reason"
            multiline
            rows={3}
            label="motivo da arbitrariedade de valor"
            color="secondary"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            onInvalid={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.setCustomValidity('Preencha este campo')
            }
            onInput={(event: ChangeEvent<HTMLInputElement>) =>
              event.target.setCustomValidity('')
            }
          />
        </Grid>
      </Grid>
      {open && (
        <ButtonsSpace>
          <ConfirmationDialog
            id="clear-btn"
            button={<OutlinedButton>limpar</OutlinedButton>}
            text="limpar os valores de arbitrariedade"
            modalCallback={() => handleClearValues()}
          />
          <FilledButton
            id="arbitrary-update"
            width="md"
            form="arbitrary"
            type="submit"
            disabled={submitLoading}
          >
            {submitLoading ? <CircularProgress size={22} /> : 'aplicar'}
          </FilledButton>
        </ButtonsSpace>
      )}
    </BoxContainer>
  );
}
