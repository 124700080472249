/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { samplesListAPI, workOrderAPI } from '../../../../api';
import {
  convertPropertyType,
  convertRegistrationUf,
  PropertyType,
  RegistrationUf,
  StatusCode,
} from '../../../../api/enumerations';
import { SaveElementData } from '../../../../api/sample/types';
import { SampleElementData } from '../../../../api/samplesLists/types';
import FilterInput from '../../../../components/Sections/Sample/Filter';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import {
  DialogTitle,
  InputTitle,
  StyledInput,
} from '../../../../components/UI/Typography';
import { IconCloseMS } from '../../../../constants/icons';
import { Constants } from '../../../../constants/sampleCreation';
import { SelectProps } from '../../../../constants/selectOptions';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';
import { StyledTooltip } from '../../styles';
import { ElementTable } from '../ElementTable';
import { ButtonBox, DialogContainer } from './styles';

interface UseSavedElementsProps {
  workOrderUf: RegistrationUf;
  workOrderCity: string;
  workOrderPropertyType: PropertyType;
  sampleData: SaveElementData[] | undefined;
  regionNamesList: SelectProps[];
  isDisabled: boolean;
  maxElements: number;
  osId: number | undefined;
  updateSample: () => Promise<void>;
}

export function UseSavedElements({
  workOrderUf,
  workOrderCity,
  workOrderPropertyType,
  sampleData,
  regionNamesList,
  isDisabled,
  maxElements,
  osId,
  updateSample,
}: UseSavedElementsProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [regionName, setRegionName] = useState('');
  const [sampleId, setSampleId] = useState<number>();
  const [sampleElements, setSampleElements] = useState<SampleElementData[]>([]);

  const [checkAll, setCheckAll] = useState(false);
  const [checkedElements, setCheckedElements] = useState<SaveElementData[]>([]);

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const dialog = useGeneral();

  const remainingCapacity = sampleData ? maxElements - sampleData.length : 0;

  const getSampleElements = useCallback(async () => {
    if (!sampleId) {
      handleSnackbar('Algo deu errado, tente novamente.', true);
      return;
    }

    setLoading(true);
    try {
      const response = await samplesListAPI.getSampleElements(sampleId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSampleElements(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [sampleId]);

  useEffect(() => {
    if (sampleId) {
      getSampleElements();
    } else {
      setCheckAll(false);
      setSampleElements([]);
    }
  }, [sampleId]);

  useEffect(() => {
    if (checkAll) {
      setCheckedElements(sampleElements);
    } else {
      setCheckedElements([]);
    }
  }, [checkAll]);

  const handleCheckElement = (element: SaveElementData): void => {
    setCheckedElements((prevSelected) => {
      const isElementSelected = prevSelected.some((el) => el.id === element.id);
      if (isElementSelected) {
        return prevSelected.filter((el) => el.id !== element.id);
      }
      if (
        sampleData &&
        sampleData.length + prevSelected.length >= maxElements
      ) {
        handleSnackbar('Quantidade máxima de elementos alcançada', true);
        return prevSelected;
      }
      return [...prevSelected, element];
    });
  };

  const useElementsFromSavedList = async (): Promise<void> => {
    setSubmitLoading(true);
    const elementsToCopy = checkedElements.filter(
      (checkedElement) =>
        !sampleData?.some(
          (element) =>
            (element.id === checkedElement.id &&
              element.search_sample_id === checkedElement.search_sample_id) ||
            element.id === checkedElement.search_sample_id ||
            element.search_sample_id === checkedElement.id
        )
    );

    if (elementsToCopy.length === 0) {
      setSubmitLoading(false);
      handleSnackbar('Elemento já salvo na amostra', true);
      return;
    }

    if (osId) {
      const elementsIds = checkedElements.map((element) => element.id);
      const data = {
        saved_samples_ids: elementsIds,
      };

      try {
        const response = await workOrderAPI.copySavedSampleElements(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Amostra salva com sucesso!', false);
        dialog.handleClose();
        setSubmitLoading(false);
        updateSample();
      } catch (error) {
        setSubmitLoading(false);
        handleSnackbar(getErrorMessage(error), true);
      }
    }
  };

  return (
    <>
      <StyledTooltip
        title="Quantidade máxima de elementos alcançada"
        placement="top"
        disableHoverListener={!isDisabled}
      >
        <span style={{ width: '100%' }}>
          <RoundedButton
            disableTouchRipple
            disabled={regionNamesList.length === 0 || isDisabled}
            onClick={dialog.handleOpen}
            model="dark"
          >
            {Constants.useSavedElements}
          </RoundedButton>
        </span>
      </StyledTooltip>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="Use saved elements"
      >
        <DialogContainer>
          <CloseIcon onClick={dialog.handleClose}>{IconCloseMS}</CloseIcon>
          <DialogTitle>{Constants.savedElements}</DialogTitle>
          <Grid container spacing={3} marginBottom="21px">
            <Grid item xs={3}>
              <FilterInput
                label={Constants.region}
                selectOptions={regionNamesList}
                selectedOption={regionName}
                setSelectedOption={setRegionName}
                disabled={regionNamesList.length === 0}
                setSampleId={setSampleId}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTitle>{Constants.city}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {workOrderCity}
              </StyledInput>
            </Grid>
            <Grid item xs={2}>
              <InputTitle>{Constants.uf}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {convertRegistrationUf(workOrderUf)}
              </StyledInput>
            </Grid>
            <Grid item xs={2}>
              <InputTitle>{Constants.realEstate}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {convertPropertyType(workOrderPropertyType)}
              </StyledInput>
            </Grid>
          </Grid>
          {loading ? (
            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '60px' }}>
              <CircularProgress />
            </Box>
          ) : sampleElements.length > 0 ? (
            <>
              <ElementTable
                tableData={sampleElements}
                propertyType={workOrderPropertyType}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                disabledCheckAll={sampleElements.length > remainingCapacity}
                handleCheckElement={handleCheckElement}
                checkedElements={checkedElements}
              />
              <ButtonBox>
                <OutlinedButton onClick={dialog.handleClose}>
                  {Constants.cancel}
                </OutlinedButton>
                <FilledButton
                  onClick={useElementsFromSavedList}
                  disabled={submitLoading || checkedElements.length === 0}
                >
                  {submitLoading ? (
                    <CircularProgress size={22} />
                  ) : (
                    Constants.saveOSSample
                  )}
                </FilledButton>
              </ButtonBox>
            </>
          ) : (
            <Typography textAlign="center" marginTop="60px">
              {Constants.noElements}
            </Typography>
          )}
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
