/* eslint-disable react/require-default-props */
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { PropertyType, Status } from '../../../../api/enumerations';
import {
  AggregatedFactors,
  AvmFactorsData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import protoWink from '../../../../assets/avatars/Avatar-2.png';
import { Constants } from '../../../../constants/report';
import { formatReal } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import ScatterTrendline from '../../../Charts/ScatterTrendline';
import { ExportSample } from '../../../ExportSample';
import { ArbitraryValue } from '../ArbitraryValue';
import ReportsTable from '../ReportsTable';
import {
  BoldCell,
  CellCaption,
  CellContent,
  CellHeader,
  GridContainer,
  GridTable,
  ReportTitle,
  RowCaption,
  RowHeader,
  StyledTable,
} from '../styles';
import ForceFactorsDialog from './ForceFactorsDialog';
import {
  presentAggregatedFactorAptoName,
  presentAggregatedFactorData,
  presentAggregatedFactorHouseName,
  presentAggregatedFactorName,
  presentBlendingData,
  presentCoeficientHeader,
  presentEntranceData,
  presentEntranceHeader,
  presentFactorsData,
  presentFactorsHeader,
  presentFactorsInitial,
  presentFundamentationData,
  presentFundamentationHeader,
  presentFundamentationTotal,
  presentReason,
  presentResultData,
  presentResultHeader,
  presentSanitizedData,
  presentValueData,
} from './presenter';
import {
  BoxProto,
  FundCellCaption,
  GridFactors,
  StyledCardMedia,
  TypographyProto,
} from './styles';

export interface FactorsTableProps {
  tableData: AvmFactorsData;
  chartCallback?: (img: HTMLCanvasElement, title: string) => Promise<void>;
  forceFactors?: string[];
  propertyIsHouse: boolean;
  highPrice: boolean;
  refNumber?: number | undefined;
  propertyData: WorkOrderData;
}

export function FactorsTable({
  tableData,
  chartCallback,
  forceFactors,
  propertyIsHouse,
  highPrice,
  refNumber,
  propertyData,
}: FactorsTableProps): JSX.Element {
  const [propertyValue, setPropertyValue] = useState<number>(
    tableData.aggregated_homogenization.rounded_market_value
  );
  const [arbitraryReason, setArbitraryReason] = useState<string>();
  const statusIsReport = propertyData.status === Status.REPORT;

  const { osId } = useGeneral();

  useEffect(() => {
    if (propertyData.arbitrary_value)
      setPropertyValue(propertyData.arbitrary_value);
    if (propertyData.arbitrary_value_reason)
      setArbitraryReason(propertyData.arbitrary_value_reason);
  }, [propertyData]);

  const handlePropertyValue = useCallback(
    async (value: number, reason: string): Promise<void> => {
      setPropertyValue(value);
      setArbitraryReason(reason);
    },
    []
  );

  const returnPresentAggregatedFactor = (): { [key: string]: string } => {
    switch (propertyData.real_estate_kind) {
      case PropertyType.HOUSE:
        return presentAggregatedFactorHouseName;
      case PropertyType.APARTMENT:
        return presentAggregatedFactorAptoName;
      default:
        return presentAggregatedFactorName;
    }
  };

  const returnAggregatedFactor = (): AggregatedFactors[] => {
    if (propertyData.real_estate_kind === PropertyType.APARTMENT) {
      const data = tableData.aggregated_factors;
      const newObject = data.slice(0, 1).concat(data.slice(2));
      return newObject;
    }
    return tableData.aggregated_factors;
  };

  return (
    <>
      <Box display="flex" gap={3}>
        <ExportSample osId={osId} />
        {forceFactors && (
          <ForceFactorsDialog
            refNumber={refNumber || 0}
            tableData={tableData}
            osForceFactors={forceFactors}
            outlineButon
          />
        )}
      </Box>
      {statusIsReport && (
        <ArbitraryValue
          propertyData={propertyData}
          initialValue={tableData.aggregated_homogenization.market_value}
          arbitraryCallback={handlePropertyValue}
        />
      )}
      <GridContainer container>
        <GridTable item xs={12}>
          <ReportTitle>{Constants.reportTitle}</ReportTitle>
        </GridTable>
        <GridTable item xs={12}>
          <StyledTable>
            <TableHead>
              <RowCaption>
                <CellCaption
                  colSpan={5}
                  sx={{ fontSize: '24px', textAlign: 'center' }}
                >
                  {formatReal(propertyValue)}
                  {propertyValue !==
                    tableData.aggregated_homogenization.rounded_market_value &&
                    ' (arbitrado)'}
                </CellCaption>
              </RowCaption>
            </TableHead>
          </StyledTable>
        </GridTable>
        {arbitraryReason &&
          propertyValue !==
            tableData.aggregated_homogenization.rounded_market_value && (
            <GridTable item xs={12}>
              <ReportsTable
                ariaLabel="motivo da arbitrariedade"
                totalCol={1}
                title="Motivo da arbitrariedade de valor"
                bodyData={presentReason(arbitraryReason)}
              />
            </GridTable>
          )}
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="resultado da avaliação"
            totalCol={5}
            title="Resultado da avaliação"
            headerData={presentResultHeader(tableData)}
            bodyData={presentResultData(tableData)}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela preços">
              <TableBody>
                {presentFactorsInitial(tableData).map((row) => (
                  <TableRow key={row.label}>
                    <BoldCell align="left">{row.label}</BoldCell>
                    <CellContent align="right">{row.value}</CellContent>
                    <BoldCell align="left">{row.secondLabel}</BoldCell>
                    <CellContent align="right">{row.secondValue}</CellContent>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="entrada de dados"
            totalCol={11}
            title={Constants.factorsEntrance}
            headerData={presentEntranceHeader(propertyIsHouse)}
            bodyData={tableData.factors.map((data, ind) =>
              presentEntranceData(
                data.input_data,
                ind,
                propertyIsHouse,
                highPrice
              )
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <ReportsTable
            ariaLabel="tabela fatores"
            totalCol={11}
            title={Constants.factors}
            headerData={presentFactorsHeader(propertyIsHouse)}
            bodyData={tableData.factors.map((data, ind) =>
              presentFactorsData(data, ind, propertyIsHouse)
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela validação de coeficiente">
              <TableHead>
                <RowHeader>
                  {presentCoeficientHeader.map((header) => (
                    <CellHeader
                      align="center"
                      minwidth={header.width}
                      key={header.label}
                    >
                      {header.label}
                    </CellHeader>
                  ))}
                </RowHeader>
              </TableHead>
              <TableBody>
                {returnAggregatedFactor().map((data, ind) => (
                  <TableRow key={`${data.mean_ppsm}${data.std_ppsm}`}>
                    <CellContent align="center">{ind + 1}</CellContent>
                    <BoldCell align="left">
                      {returnPresentAggregatedFactor()[data.name]}
                    </BoldCell>
                    {presentAggregatedFactorData(data).map((row) => (
                      <CellContent align="center" key={row.label}>
                        {row.value}
                      </CellContent>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={6} sx={{ pr: '20px' }}>
          <ReportsTable
            ariaLabel="tabela saneamento"
            totalCol={2}
            title={Constants.factorsSani}
            labelValueData={presentSanitizedData(
              tableData.aggregated_homogenization
            )}
          />
        </GridTable>
        <GridTable item xs={6}>
          <ReportsTable
            ariaLabel="tabela homogeneizada"
            totalCol={2}
            title={Constants.factorsHomog}
            labelValueData={presentBlendingData(
              tableData.aggregated_homogenization
            )}
          />
          <Box my="10px" />
          <ReportsTable
            ariaLabel="tabela valor"
            totalCol={2}
            title={Constants.factorsValue}
            labelValueData={presentValueData(
              tableData.aggregated_homogenization,
              propertyIsHouse
            )}
          />
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela grau de fundamentação">
              <TableHead>
                <RowCaption>
                  <CellCaption colSpan={6}>{Constants.factorsFund}</CellCaption>
                </RowCaption>
                <RowHeader>
                  {presentFundamentationHeader.map((header) => (
                    <CellHeader
                      align="center"
                      minwidth="80px"
                      key={header.label}
                    >
                      {header.label}
                    </CellHeader>
                  ))}
                </RowHeader>
              </TableHead>
              <TableBody>
                {presentFundamentationData(tableData).data.map((row) => (
                  <TableRow key={row.item}>
                    <CellContent align="center">{row.item}</CellContent>
                    <CellContent align="center">{row.description}</CellContent>
                    <CellContent align="center">{row.third}</CellContent>
                    <CellContent align="center">{row.second}</CellContent>
                    <CellContent align="center">{row.first}</CellContent>
                    <BoldCell align="center" sx={{ color: '#B038FA' }}>
                      {row.point}
                    </BoldCell>
                  </TableRow>
                ))}
                <TableRow>
                  <BoldCell colSpan={5}>{Constants.sum}</BoldCell>
                  <BoldCell align="center">
                    {presentFundamentationData(tableData).total_score}
                  </BoldCell>
                </TableRow>
                <TableRow>
                  <CellContent colSpan={6}>
                    {Constants.fundObservation}
                  </CellContent>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <TableContainer>
            <StyledTable aria-label="tabela grau de fundamentação">
              <TableHead>
                <RowCaption>
                  <CellCaption colSpan={6}>
                    {Constants.factorsFundScore}
                  </CellCaption>
                </RowCaption>
              </TableHead>
              <TableBody>
                {presentFundamentationTotal().map((row, i) => (
                  <TableRow key={row[i].value}>
                    {row.map((data) => (
                      <CellContent align="center" key={data.label}>
                        {data.value}
                      </CellContent>
                    ))}
                  </TableRow>
                ))}
                <RowHeader>
                  <CellHeader colSpan={3}>{Constants.total}</CellHeader>
                  <CellHeader align="center">
                    {presentFundamentationData(tableData).total_score}
                  </CellHeader>
                </RowHeader>
                <RowCaption>
                  <FundCellCaption colSpan={3}>
                    {Constants.factorsFund}
                  </FundCellCaption>
                  <CellCaption>{tableData.scores.reasoning_level}</CellCaption>
                </RowCaption>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </GridTable>
        <GridTable item xs={12}>
          <ReportTitle>{Constants.infGraphs}</ReportTitle>
        </GridTable>
        <GridTable item xs={6}>
          <ScatterTrendline
            chart={tableData?.chart}
            chartCallback={chartCallback}
          />
        </GridTable>
        <GridFactors item xs={6}>
          <BoxProto />
          {forceFactors && (
            <>
              <BoxProto>
                <StyledCardMedia image={protoWink} title="proto" />
                <TypographyProto>{Constants.protoSuggestion}</TypographyProto>
              </BoxProto>
              <ForceFactorsDialog
                tableData={tableData}
                osForceFactors={forceFactors}
                refNumber={refNumber || 0}
              />
            </>
          )}
        </GridFactors>
      </GridContainer>
    </>
  );
}
