/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */

import { CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { samplesListAPI, workOrderAPI } from '../../../../api';
import {
  convertPropertyType,
  convertRegistrationUf,
  PropertyType,
  RegistrationUf,
  StatusCode,
} from '../../../../api/enumerations';
import { SaveElementData } from '../../../../api/sample/types';
import FilterInput from '../../../../components/Sections/Sample/Filter';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../../components/UI/Button';
import { StyledDialog } from '../../../../components/UI/Dialog';
import { InputTitle, StyledInput } from '../../../../components/UI/Typography';
import {
  IconCloseMS,
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/sampleCreation';
import { SelectProps } from '../../../../constants/selectOptions';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';
import { theme } from '../../../../styles/theme';
import { ElementTable } from '../ElementTable';
import { AddSample } from './AddSample';
import {
  ArrowButton,
  ButtonBox,
  DialogContainer,
  GridButton,
  GridTable,
  TableTitle,
} from './styles';

interface SaveElementsProps {
  workOrderUf: RegistrationUf;
  workOrderCity: string;
  workOrderPropertyType: PropertyType;
  sampleData: SaveElementData[] | undefined;
  regionNamesList: SelectProps[];
  isDisabled: boolean;
  maxElements: number;
  osId: number | undefined;
  updateSample: () => Promise<void>;
  updateRegionList: () => Promise<void>;
}

export function SaveElements({
  workOrderUf,
  workOrderCity,
  workOrderPropertyType,
  sampleData,
  regionNamesList,
  maxElements,
  isDisabled,
  osId,
  updateSample,
  updateRegionList,
}: SaveElementsProps): JSX.Element {
  const [recordSampleLoading, setRecordSampleLoading] = useState(false);
  const [newSampleLoading, setNewSampleLoading] = useState(false);
  const [saveOsSampleLoading, setSaveOsSampleLoading] = useState(false);
  const [openNewSampleDialog, setOpenNewSampleDialog] = useState(false);

  const [regionName, setRegionName] = useState('');
  const [sampleListId, setSampleListId] = useState<number>();
  const [savedElements, setSavedElements] = useState<SaveElementData[]>([]);
  const [searchedElements, setSearchedElements] = useState<SaveElementData[]>(
    []
  );
  const [checkAllSavedElements, setCheckAllSavedElements] = useState(false);
  const [checkAllSearchedElements, setCheckAllSearchedElements] =
    useState(false);
  const [checkedSavedElements, setCheckedSavedElements] = useState<
    SaveElementData[]
  >([]);
  const [checkedSearchedElements, setCheckedSearchedElements] = useState<
    SaveElementData[]
  >([]);

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const dialog = useGeneral();

  const adaptFontSize = useMediaQuery(theme.breakpoints.down(1350));
  const remainingCapacity = sampleData ? maxElements - sampleData.length : 0;

  const reset = (): void => {
    setSavedElements([]);
    setSearchedElements(sampleData || []);
    setCheckedSavedElements([]);
    setCheckedSearchedElements([]);
    setSampleListId(undefined);
    dialog.handleClose();
  };

  const getSampleElements = useCallback(async () => {
    if (!sampleListId) {
      handleSnackbar('Algo deu errado, tente novamente.', true);
      return;
    }

    try {
      const response = await samplesListAPI.getSampleElements(sampleListId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSavedElements(response.data);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [sampleListId]);

  useEffect(() => {
    if (sampleData && sampleData.length > 0) {
      setSearchedElements(sampleData);
    } else {
      setSearchedElements([]);
    }
  }, [sampleData]);

  useEffect(() => {
    if (sampleListId) {
      getSampleElements();
    } else {
      setCheckAllSavedElements(false);
      setSavedElements([]);
      setCheckedSavedElements([]);
    }
  }, [sampleListId]);

  useEffect(() => {
    if (checkAllSearchedElements && sampleData) {
      setCheckedSearchedElements(sampleData);
    } else {
      setCheckedSearchedElements([]);
    }
  }, [checkAllSearchedElements]);

  useEffect(() => {
    if (checkAllSavedElements) {
      setCheckedSavedElements(savedElements);
    } else {
      setCheckedSavedElements([]);
    }
  }, [checkAllSavedElements]);

  // This function is used to select or deselect elements from the 'saved sample list' table.
  const handleSavedCheckElement = (element: SaveElementData): void => {
    setCheckedSavedElements((prevSelected) => {
      const isElementSelected = prevSelected.some((el) => el.id === element.id);
      if (isElementSelected) {
        return prevSelected.filter((el) => el.id !== element.id);
      }
      if (searchedElements.length + prevSelected.length >= maxElements) {
        handleSnackbar('Quantidade máxima de elementos alcançada', true);
        return prevSelected;
      }
      return [...prevSelected, element];
    });
  };

  // This function is used to select or deselect elements from the 'work order sample' table.
  const handleSearchedCheckElement = (element: SaveElementData): void => {
    setCheckedSearchedElements((prevSelected) => {
      const isElementSelected = prevSelected.some((el) => el.id === element.id);
      if (isElementSelected) {
        return prevSelected.filter((el) => el.id !== element.id);
      }
      return [...prevSelected, element];
    });
  };

  const addToSavedElements = (): void => {
    setSavedElements((prevSampleElements) => {
      const newElements = checkedSearchedElements.filter(
        (checkedElement) =>
          !prevSampleElements.some(
            (sampleElement) =>
              (sampleElement.id === checkedElement.id &&
                sampleElement.search_sample_id ===
                  checkedElement.search_sample_id) ||
              sampleElement.id === checkedElement.search_sample_id ||
              sampleElement.search_sample_id === checkedElement.id
          )
      );

      return [...prevSampleElements, ...newElements];
    });
  };

  const addToSearchedElements = (): void => {
    setSearchedElements((prevSampleElements) => {
      const newElements = checkedSavedElements.filter(
        (checkedElement) =>
          !prevSampleElements.some(
            (sampleElement) =>
              (sampleElement.id === checkedElement.id &&
                sampleElement.search_sample_id ===
                  checkedElement.search_sample_id) ||
              sampleElement.id === checkedElement.search_sample_id ||
              sampleElement.search_sample_id === checkedElement.id
          )
      );

      return [...prevSampleElements, ...newElements];
    });
  };

  const saveElementsOnList = async (
    listId = sampleListId,
    newSample = false
  ): Promise<void> => {
    if (osId && listId) {
      if (!newSample) {
        setRecordSampleLoading(true);
      }
      const savedElementsIds = savedElements.map((element) => element.id);
      const data = {
        samples_ids: savedElementsIds,
      };

      try {
        const response = await workOrderAPI.saveSampleElementsOnList(
          osId,
          listId,
          data
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Amostra salva com sucesso!', false);
        updateRegionList();
        setOpenNewSampleDialog(false);
        setCheckAllSavedElements(false);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      } finally {
        if (newSample) {
          setNewSampleLoading(false);
        } else {
          setRecordSampleLoading(false);
        }
      }
    }
  };

  const useElementsFromSavedList = async (): Promise<void> => {
    if (osId) {
      setSaveOsSampleLoading(true);

      const elementsIds = checkedSavedElements.map((element) => element.id);
      const data = {
        saved_samples_ids: elementsIds,
      };

      try {
        const response = await workOrderAPI.copySavedSampleElements(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Amostra salva com sucesso!', false);
        dialog.handleClose();
        setSaveOsSampleLoading(false);
        updateSample();
      } catch (error) {
        setSaveOsSampleLoading(false);
        handleSnackbar(getErrorMessage(error), true);
      }
    }
  };

  return (
    <>
      <RoundedButton
        disableTouchRipple
        onClick={dialog.handleOpen}
        model="dark"
        disabled={regionNamesList.length === 0 && !sampleData}
        sx={{
          fontSize: adaptFontSize ? '14px' : '16px',
        }}
      >
        {Constants.saveElement}
      </RoundedButton>
      <StyledDialog
        open={dialog.open}
        onClose={reset}
        aria-labelledby="Save sample elements"
      >
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <FilterInput
                label={Constants.region}
                selectOptions={regionNamesList}
                selectedOption={regionName}
                setSelectedOption={setRegionName}
                disabled={regionNamesList.length === 0}
                setSampleId={setSampleListId}
              />
            </Grid>
            <Grid item xs={1.5}>
              <InputTitle>{Constants.city}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {workOrderCity}
              </StyledInput>
            </Grid>
            <Grid item xs={1.5}>
              <InputTitle>{Constants.uf}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {convertRegistrationUf(workOrderUf)}
              </StyledInput>
            </Grid>
            <Grid item xs={1.5}>
              <InputTitle>{Constants.realEstate}</InputTitle>
              <StyledInput sx={{ minWidth: '100%' }}>
                {convertPropertyType(workOrderPropertyType)}
              </StyledInput>
            </Grid>
            <Grid item xs={5.5} />
          </Grid>
          <Grid container>
            <GridTable item xs={5.7}>
              <TableTitle>{Constants.savedElements}</TableTitle>
              <ElementTable
                tableData={savedElements}
                propertyType={workOrderPropertyType}
                checkAll={checkAllSavedElements}
                setCheckAll={setCheckAllSavedElements}
                disabledCheckAll={
                  savedElements.length === 0 ||
                  savedElements.length > remainingCapacity
                }
                handleCheckElement={handleSavedCheckElement}
                checkedElements={checkedSavedElements}
              />
              <ButtonBox>
                <OutlinedButton
                  width="ms"
                  onClick={() => saveElementsOnList()}
                  disabled={!sampleListId || recordSampleLoading}
                >
                  {recordSampleLoading ? (
                    <CircularProgress size={22} />
                  ) : (
                    Constants.recordSample
                  )}
                </OutlinedButton>
                <AddSample
                  open={openNewSampleDialog}
                  setOpen={setOpenNewSampleDialog}
                  submitLoading={newSampleLoading}
                  setSubmitLoading={setNewSampleLoading}
                  workOrderUf={workOrderUf}
                  workOrderCity={workOrderCity}
                  workOrderPropertyType={workOrderPropertyType}
                  saveElementsOnList={saveElementsOnList}
                  isDisabled={savedElements.length === 0 || !!sampleListId}
                />
              </ButtonBox>
            </GridTable>
            <GridButton item xs={0.6} id="btn-container">
              <ArrowButton
                onClick={addToSavedElements}
                disabled={checkedSearchedElements.length === 0}
              >
                {IconKeyboardDoubleArrowLeftMS}
              </ArrowButton>
              <ArrowButton
                onClick={addToSearchedElements}
                disabled={checkedSavedElements.length === 0 || isDisabled}
              >
                {IconKeyboardDoubleArrowRightMS}
              </ArrowButton>
            </GridButton>
            <GridTable item xs={5.7}>
              <TableTitle>{Constants.searchedElements}</TableTitle>
              <ElementTable
                tableData={searchedElements}
                propertyType={workOrderPropertyType}
                checkAll={checkAllSearchedElements}
                setCheckAll={setCheckAllSearchedElements}
                handleCheckElement={handleSearchedCheckElement}
                checkedElements={checkedSearchedElements}
              />
              <ButtonBox>
                <OutlinedButton width="ms" onClick={reset}>
                  {Constants.cancel}
                </OutlinedButton>
                <FilledButton
                  width="ms"
                  onClick={useElementsFromSavedList}
                  disabled={
                    saveOsSampleLoading ||
                    searchedElements.length === sampleData?.length
                  }
                >
                  {saveOsSampleLoading ? (
                    <CircularProgress size={22} />
                  ) : (
                    Constants.saveOSSample
                  )}
                </FilledButton>
              </ButtonBox>
            </GridTable>
          </Grid>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
